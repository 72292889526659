import type { ThemeOptions } from '@mui/material/styles';

export const tableOverrides = (): ThemeOptions['components'] => ({
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: 'none',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: 'none',
      },
    },
  },
});
