import { alpha, Breakpoint, createTheme, lighten } from '@mui/material/styles';
import { tableOverrides } from './tableOverrides';

import { Theme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    special: true;
    title: true;
  }
}

const baseTheme = createTheme({
  palette: {
    mode: 'dark' as PaletteMode,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1600,
      xl: 1900,
    },
  },
});

export const myGroupsTheme = createTheme(baseTheme, {
  palette: {
    divider: '#E5E5E5',
    primary: {
      main: '#00ABFF',
    },
    text: {
      primary: '#393636',
      secondary: '#848484',
    },
    background: {
      paper: '#E5E5E5',
      default: '#FFFFFF',
    },
    demi: {
      white: '#FFFFFF',
      blue: '#00ABFF',
      green: '#58E490',
      yellow: '#FFD84D',
      red: '#FF5C5C',
    },
    whiteBg: {
      main: '#FFFFFF',
    },
    icon: {
      main: '#3276b5',
      light: '#848484',
      dark: '#393636',
    },
    title: {
      primary: '#3276b5',
      secondary: '#234666',
      black: '#000000',
      dark: '#393636',
    },
    tertiary: {
      main: '#202020',
    },
  },
  typography: {
    h1: {
      color: '#393636',
      fontSize: 32,
      fontFamily: 'Barlow',
      fontWeight: 700,
      fontStyle: 'normal',
      margin: '1rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 26,
      },
    },
    h2: {
      color: '#393636',
      fontSize: 24,
      fontFamily: 'Barlow',
      fontWeight: 700,
      fontStyle: 'normal',
      margin: '1rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 20,
      },
    },
    h6: {
      color: '#393636',
      fontSize: 24,
      fontFamily: 'Barlow',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    subtitle1: {
      color: '#848484',
      fontSize: 11,
      fontFamily: 'Barlow',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: 2.5,
    },
    body1: {
      color: '#393636',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 2,
    },
    body2: {
      color: '#848484',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 2,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-text': {
            color: '#393636',
          },
        },
      },
    },
  },
});

export const characterBuilderTheme = createTheme(
  {
    ...baseTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 664,
        md: 1024,
        lg: 1648,
        xl: 1648,
      },
    },
  },
  {
    palette: {
      divider: alpha('#F1CE86', 0.4),
      primary: {
        main: '#5C1C16',
        dark: '#581B15',
        light: '#7D261E',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#F1CE86',
        dark: '#EFC774',
        light: '#F3D598',
        contrastText: '#000000',
      },
      info: {
        main: '#EDEDED',
        dark: '#D5D5D5',
        light: '#EEEEEE',
        contrastText: '#3276b5',
      },
      warning: {
        main: '#4A4030',
        light: '#665C4E',
        dark: '#3E3629',
        contrastText: '#F1CE86',
      },
      success: {
        main: '#3276b5',
        light: '#58A3EA',
        dark: '#2D6AA2',
        contrastText: '#FFFFFF',
      },
      demiplane: {
        main: '#00ABFF',
        light: '#58A3EA',
        dark: '#2D6AA2',
        contrastText: '#FFFFFF',
      },
      purchase: {
        main: '#58E490',
        light: '#90ffc1',
        dark: '#04b162',
        contrastText: '#FFFFFF',
      },
      cancel: {
        main: '#687079',
        light: '#969ea8',
        dark: '#3d454d',
        contrastText: '#FFFFFF',
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#BFBFBF',
      },
      grey: {
        400: '#D1D1D1',
        600: '#444242',
        700: '#3B3E45',
        800: '#323232',
      },
      background: {
        paper: '#221C1B',
        default: '#202020',
      },
      base: {
        base0: '#0F0F0F',
        base1: '#202020',
        base2: '#2A2A2A',
        base3: '#3F3F3F',
        base4: '#AAA694',
        base5: '#687079',
        base6: '#C8C7C7',
      },
      demi: {
        white: '#FFFFFF',
        blue: '#00ABFF',
        green: '#58E490',
        yellow: '#FFD84D',
        red: '#FF5C5C',
      },
      gold: {
        gold1: '#F1CE86',
        gold2: '#FFF2D8',
      },

      //# tertiary and whiteBG are needed for hover over displays inside elements
      tertiary: {
        main: '#202020',
      },
      whiteBg: {
        main: '#FFFFFF',
      },
    },
    typography: {
      fontFamily: ['goodOT', 'Roboto'].join(', '),
      fontWeightExtraLight: 400,
      fontWeightLight: 500,
      fontWeightRegular: 700,
      fontWeightMedium: 700,
      fontWeightHeavy: 900,
      title: {
        fontFamily: 'GoodOTCondBold',
      },
      common: {
        fontFamily: 'Nunito',
      },
      special: {
        fontFamily: 'Taroca',
      },
      default: {
        fontFamily: 'GoodOT',
      },
      label: {
        fontFamily: 'Taroca',
        fontSize: '20px',
        fontWeight: 400,
      },
      h1: {
        fontSize: 24,
        fontFamily: 'GoodOTCondBold',
        fontWeight: 700,
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 26,
        },
      },
      h2: {
        fontSize: 21,
        fontFamily: 'GoodOTCondBold',
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 23,
        },
      },
      h3: {
        fontSize: 18,
        fontFamily: 'GoodOTCondBold',
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 20,
        },
      },
      h4: {
        fontSize: 16,
        fontFamily: 'Nunito',
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 18,
        },
      },
      h5: {
        fontSize: 14,
        fontFamily: 'Nunito',
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 16,
        },
      },
      h6: {
        fontSize: 12,
        fontFamily: 'Nunito',
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 14,
        },
      },
      subtitle1: {
        fontFamily: 'GoodOTCondBold',
        fontSize: 11,
        fontWeight: 400,
      },
      subtitle2: {
        fontFamily: 'Nunito',
        fontSize: 9,
        fontWeight: 400,
      },
    },
    components: {
      ...tableOverrides(),
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'uppercase',
            fontFamily: 'goodOtCondBold',
            lineHeight: 'normal',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            lineHeight: '24px',
            fontFamily: 'GoodOTCondBold',
            color: baseTheme.palette.text.primary,
            textTransform: 'uppercase',
            transform: 'none',
            display: 'unset',
            position: 'relative',

            '&.Mui-focused': {
              color: baseTheme.palette.text.primary,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: 'goodOt',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            //TODO: is it possible to use base colors from above palette instead of hardcode?
            backgroundColor: '#202020',
            color: baseTheme.palette.text.primary,
            fontSize: 16,
            input: {
              fontFamily: 'Nunito',
              color: baseTheme.palette.text.primary,
              '::placeholder': {
                fontFamily: 'Nunito',
                color: baseTheme.palette.text.primary,
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        },
      },
    },
  }
);

export const characterSheetTheme = createTheme(
  {
    ...baseTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 743,
        md: 1024,
        lg: 1366,
        xl: 1920,
      },
    },
  },
  {
    palette: {
      divider: '#F1CE86',
      primary: {
        main: '#00ABFF',
        dark: '#0089cc',
        light: '#7D261E',
        contrastText: '#FFFFFF',
      },
      contrastText: '#fff',
      secondary: {
        main: '#3F3F3F',
        dark: '#EFC774',
        light: '#F3D598',
        contrastText: '#000000',
      },
      info: {
        main: '#EDEDED',
        dark: '#D5D5D5',
        light: '#EEEEEE',
        contrastText: '#3276b5',
      },
      warning: {
        main: '#4A4030',
        light: '#665C4E',
        dark: '#3E3629',
        contrastText: '#F1CE86',
      },
      success: {
        main: '#2AAC5E',
        light: '#58A3EA',
        dark: '#2D6AA2',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#8A3C37',
        contrastText: '#FFFFFF',
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#BFBFBF',
      },
      grey: {
        400: '#D1D1D1',
        500: '#9e9e9e',
        600: '#444242',
        700: '#3B3E45',
        800: '#323232',
      },
      background: {
        paper: '#221C1B',
        default: 'rgba(32,32,32,0.95)',
      },
      base: {
        base0: '#0F0F0F',
        base1: '#202020',
        base2: '#2A2A2A',
        base3: '#3F3F3F',
        base4: '#AAA694',
        base5: '#687079',
        base6: '#C8C7C7',
      },
      border: '#555555',
      demi: {
        white: '#FFFFFF',
        blue: '#00ABFF',
        green: '#58E490',
        yellow: '#FFD84D',
        red: '#FF5C5C',
      },
      gold: {
        main: '#F1CE86',
        gold1: '#F1CE86',
        fadedGold1: 'rgba(241,206,134,0.1)',
        gold2: '#FFF2D8',
      },

      //# tertiary and whiteBG are needed for hover over displays inside elements
      tertiary: {
        main: '#202020',
      },
      whiteBg: {
        main: '#FFFFFF',
      },
      purchase: {
        main: '#58E490',
        light: '#90ffc1',
        dark: '#04b162',
        contrastText: '#FFFFFF',
      },
      cancel: {
        main: '#687079',
        light: '#969ea8',
        dark: '#3d454d',
        contrastText: '#FFFFFF',
      },
    },
    typography: {
      fontWeightExtraLight: 400,
      fontWeightLight: 500,
      fontWeightRegular: 700,
      fontWeightMedium: 700,
      fontWeightHeavy: 900,

      title: {
        fontFamily: 'Taroca',
        fontSize: '22px',
        fontWeight: 700,
      },
      common: {
        fontFamily: 'Barlow',
      },
      label: {
        fontFamily: 'Taroca',
        fontSize: '20px',
        fontWeight: 400,
      },
      special: {
        fontFamily: 'Barlow',
        fontSize: '14px',
        fontWeight: 700,
        color: '#C8C7C7',
      },
      default: {
        fontFamily: 'Barlow',
      },
      caption: {
        fontFamily: 'Barlow',
        fontSize: 9,
        fontWeight: 700,
        fontStyle: 'bold',
      },
      experience: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: '#C8C7C7',
      },
      clickable: {
        fontFamily: 'GoodOT',
        fontSize: 18,
        fontWeight: 700,
      },
      h1: {
        fontSize: 24,
        fontFamily: 'GoodOTCondBold',
        fontWeight: 400,
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 26,
        },
      },
      h2: {
        fontSize: 21,
        fontFamily: 'Barlow',
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 23,
        },
      },
      h3: {
        fontSize: 18,
        fontFamily: 'goodOTCondBold',
        fontWeight: 400,
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 20,
        },
      },
      h4: {
        fontSize: 16,
        fontFamily: 'GoodOT',
        fontWeight: 700,
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 18,
        },
      },
      h5: {
        fontSize: 14,
        fontFamily: 'GoodOTCondBold',
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 16,
        },
      },
      h6: {
        fontSize: 12,
        fontFamily: 'GoodOTCondBold',
        letterSpacing: 0.5,
        [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
          fontSize: 14,
        },
      },
      body1: {
        fontFamily: 'goodOt',
        fontSize: 12,
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 10,
        fontFamily: 'GoodOT',
        fontWeight: 700,
      },
      subtitle2: {
        fontSize: 8,
        fontFamily: 'Barlow',
        fontWeight: 700,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'uppercase',
            fontFamily: 'GoodOTCondBold',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: 14,
            '&.Mui-focused': {
              '& > fieldset': { borderColor: '#F1CE86 !important' },
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: '#FFFFFF',
          },

          colorPrimary: {
            '&.Mui-checked': {
              // Controls checked color for the thumb
              color: '#F1CE86',
              '&.Mui-disabled': {
                color: baseTheme.palette.grey[800],
              },
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.2,
            backgroundColor: '#fff',
            '.Mui-checked.Mui-checked + &': {
              // Controls checked color for the track
              opacity: 0.7,
              backgroundColor: '#F1CE86',
              '&.Mui-disabled': {
                backgroundColor: baseTheme.palette.grey[800],
              },
            },
          },
        },
      },
    },
  }
);

export const characterLibraryTheme = createTheme(characterSheetTheme, {
  typography: {
    fontWeight: 700,
    h1: {
      fontSize: 48,
      fontFamily: 'Barlow',
      fontWeight: 700,
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 48,
      },
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 24,
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
      fontFamily: 'Barlow',
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 24,
      },
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: 'Barlow',
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 20,
      },
    },
    h5: {
      fontSize: 16,
      fontWeight: 700, // changed fontweight
      fontFamily: 'Barlow',
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 16,
      },
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: 'Barlow',
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 14,
      },
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 700,
      fontFamily: 'Barlow',
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 12,
      },
    },
    subtitle2: {
      fontSize: 10,
      fontWeight: 700,
      fontFamily: 'Barlow',
      [baseTheme.breakpoints.down('sm' as Breakpoint)]: {
        fontSize: 10,
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          background: theme.palette.demi.blue,
          borderRadius: '.25rem',
          cursor: 'pointer',
          '&:hover': {
            background: lighten(theme.palette.demi.blue, 0.2),
          },
        }),
      },
    },
  },
});

export const topNavTheme = createTheme(
  {
    ...baseTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 743,
        md: 1024,
        lg: 1366,
        xl: 1920,
      },
    },
  },
  {
    typography: {
      h5: {
        fontFamily: 'Barlow',
        fontWeight: 700,
        fontSize: 24,
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      h4: {
        fontFamily: 'Barlow',
        fontWeight: 700,
        fontSize: 18,
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      h3: {
        fontFamily: 'Barlow',
        fontWeight: 700,
        fontSize: 16,
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      h2: {
        fontFamily: 'Barlow',
        fontWeight: 700,
        fontSize: 14,
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      h1: {
        fontFamily: 'Barlow',
        fontWeight: 700,
        fontSize: 12,
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
    },
    palette: {
      primary: {
        main: '#00ABFF',
        dark: '#0089cc',
        light: '#7D261E',
        contrastText: '#3276b5',
      },
      secondary: {
        main: '#3F3F3F',
        dark: '#EFC774',
        light: '#F3D598',
        contrastText: '#000000',
      },
      base: {
        base0: '#101014',
        base1: '#1B1C22',
        base2: '#272831',
        base3: '#32333D',
        base4: '#4A4D5F',
        base5: '#5F6774',
        base6: '#C1C4D2',
        base7: '#DBDEE4',
      },
      demi: {
        white: '#FFFFFF',
        blue: '#00ABFF',
        green: '#58E490',
        yellow: '#FFD84D',
        red: '#FF5C5C',
      },
      accent: {
        accent0: '#58E490',
        accent1: '#FFD84D',
        accent2: '#FF5C5C',
        accent3: '#24D193',
      },
    },
  }
);
